import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RoutesApp } from "./router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

window.ZOHO.embeddedApp.on("PageLoad", function (data) {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <>
      <RoutesApp Idquote={data} />
      <ToastContainer />
    </>
  );
});

window.ZOHO.embeddedApp.init();
