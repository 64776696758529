import React from "react";
import { Stack, Autocomplete, TextField } from "@mui/material";

const PaymentForm = ({ paymentMethod, setPaymentMethod, text }) => {
  return (
    <Autocomplete
      onChange={(e, newValue) => {
        if (newValue?.[newValue.length - 1]?.value === "select_all") {
          setPaymentMethod(
            payment.filter((item) => item.value !== "select_all")
          );
          return;
        }
        setPaymentMethod(newValue);
      }}
      value={paymentMethod}
      multiple
      id="tags-outlined"
      options={payment.filter(
        (item) => !paymentMethod.some((method) => method.value === item.value)
      )}
      size="small"
      sx={{ width: "100%" }}
      getOptionLabel={(option) => option.title}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          size="small"
          color="success"
          {...params}
          label={text.textfield2}
          placeholder="Select payment methods"
        />
      )}
    />
  );
};

export default PaymentForm;

const payment = [
  { title: "Select All", value: "select_all" },
  { title: "Credit", value: "credit_card" },
  { title: "Debito", value: "debit_card" },
  { title: "Pix", value: "pix" },
  { title: "Voucher", value: "voucher" },
  { title: "Boleto", value: "boleto" },
  { title: "Credito e boleto", value: "credit_card_and_boleto"},
  { title: "Mais de um cartão de crédito", value: "credits"}
];
