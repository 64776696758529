// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainOut {
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf0f4;
  padding: 36px;
  max-height: 86.5vh;
  min-height: 86.5vh;
}
.MuiBox-root {
  padding: 0 !important;
}

.Settings {
  display: flex; /* Usando flexbox */
  justify-content: center; /* Centralizando horizontalmente */
  background-color: #fff;
  width: 70%;
  padding-top: 0vh;
  border-radius: 10px;
  padding-bottom: 10px;
  height: 89vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa,EAAE,mBAAmB;EAClC,uBAAuB,EAAE,kCAAkC;EAC3D,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,YAAY;AACd","sourcesContent":[".mainOut {\n  overflow-y: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #edf0f4;\n  padding: 36px;\n  max-height: 86.5vh;\n  min-height: 86.5vh;\n}\n.MuiBox-root {\n  padding: 0 !important;\n}\n\n.Settings {\n  display: flex; /* Usando flexbox */\n  justify-content: center; /* Centralizando horizontalmente */\n  background-color: #fff;\n  width: 70%;\n  padding-top: 0vh;\n  border-radius: 10px;\n  padding-bottom: 10px;\n  height: 89vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
