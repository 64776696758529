import { useEffect, useState } from "react";
import { Stack, TextField, Typography, Container } from "@mui/material";
import Urlwebhook from "../../components/Urlwebhook/Urlwebhook";

const Webhook = ({ text, orgVariable }) => {
  const [layouts, setLayouts] = useState([]);
  return (
    <Container sx={{ height: "70vh", width: "800px", paddingTop: "15px" }}>
      <Stack spacing={3}>
        <Typography
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            color: "#424242",
          }}
          variant="h4"
        >
          Webhook
        </Typography>
        <Stack
          spacing={3}
          direction="row"
          sx={{
            paddingLeft: "5%",
          }}
        >
          <Stack spacing={1}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "#424242" }}
            >
              {text.stage1}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "#424242" }}
            >
              {text.stage2}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "#424242" }}
            >
              {text.stage3}
            </Typography>
            <Urlwebhook orgVariable={orgVariable} />
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "#424242" }}
            >
              {text.stage4}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "#424242" }}
            >
              {text.stage5}
            </Typography>
            <Stack display="flex">
              <Stack spacing={1}>
                <Typography
                  variant="body2"
                  sx={{ paddingLeft: "10px", color: "#424242" }}
                >
                  - order.canceled
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ paddingLeft: "10px", color: "#424242" }}
                >
                  - order.paid
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ paddingLeft: "10px", color: "#424242" }}
                >
                  - order.payment_failed
                </Typography>
              </Stack>
            </Stack>
            <Typography
              variant="body1"
              sx={{ fontWeight: "500", color: "#424242" }}
            >
              {text.stage6}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Webhook;
