import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./pages/App/App";
import Settings from "./pages/Settings";
import translation from "./translation/translation.json";
import { useState, useEffect } from "react";
import "./router.css";

export const RoutesApp = ({ Idquote }) => {
  const [languageSystem, setlanguageSystem] = useState("");
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento

  useEffect(() => {
    // Obtendo o idioma do usuário
    ZOHO.CRM.CONFIG.getCurrentUser().then(function (data) {
      let userLanguage = data.users[0].locale;
      if (userLanguage === "pt_BR") {
        setlanguageSystem(translation.pt_BR);
      } else {
        setlanguageSystem(translation.en_US);
      }
      setLoading(false); // Define o carregamento como falso após a configuração do idioma
    });
  }, []);

  if (loading) {
    // Loading aqui
    return (
      <div className="container">
        <div class="loader">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<App Idquote={Idquote} text={languageSystem.quotes} />}
        />
        <Route path="/settings" element={<Settings text={languageSystem} />} />
      </Routes>
    </BrowserRouter>
  );
};
