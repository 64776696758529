// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerCopy {
  height: 130px;
  padding: 20px;
  border-radius: 5px;
}

.buttonCopy {
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: center;
  margin-left: 3%;
}

.buttonCopy:hover {
  transform: scale(1.15); /* Aumenta o tamanho do botão */
}

.box_input {
  display: flex;
}

.titleModal {
  color: rgb(27, 28, 41);
  font-size: 24px;
  margin-bottom: 5%;
  margin-left: 2%;
}

.inputText {
  width: 90%;
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalCopy/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,sBAAsB,EAAE,+BAA+B;AACzD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".containerCopy {\n  height: 130px;\n  padding: 20px;\n  border-radius: 5px;\n}\n\n.buttonCopy {\n  border: 0;\n  background: transparent;\n  cursor: pointer;\n  text-align: center;\n  margin-left: 3%;\n}\n\n.buttonCopy:hover {\n  transform: scale(1.15); /* Aumenta o tamanho do botão */\n}\n\n.box_input {\n  display: flex;\n}\n\n.titleModal {\n  color: rgb(27, 28, 41);\n  font-size: 24px;\n  margin-bottom: 5%;\n  margin-left: 2%;\n}\n\n.inputText {\n  width: 90%;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
