import "./App.css";
import Info from "../../components/info/Info";
import Products from "../../components/products/Products";
import Confirm from "../../components/Confirm/Confirm";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

export default function App({ Idquote, text }) {
  const [resp, setResp] = useState(null);
  const [dataOrg, setDataOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [button, setButton] = useState(false);
  const [installments, setInstallments] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (Idquote) {
          const idRecord = Idquote.EntityId[0];
          const recordData = await ZOHO.CRM.API.getRecord({
            Entity: "Quotes",
            RecordID: idRecord,
          });
          const resp = recordData.data[0];
          setResp(resp);

          const orgData = await ZOHO.CRM.API.getOrgVariable(
            "pagarmeforzohocrm__pagar_me"
          );
          const { Content } = orgData.Success;
          const ContentJson = JSON.parse(Content);
          setDataOrg(ContentJson);

          const installmentsData = await ZOHO.CRM.API.getRelatedRecords({
            Entity: "Quotes",
            RecordID: resp.id,
            RelatedList: "pagarmeforzohocrm__Installments",
          });

          setInstallments(installmentsData);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Idquote]);

  useEffect(() => {
    if (resp && dataOrg && installments) {
      if (installments.data && installments.data.length > 0) {
        setButton(true);
        toast.error("Já existe um pedido criado");
        return;
      } else {
        if (resp.pagarmeforzohocrm__Order_ID !== null) {
          if (resp.pagarmeforzohocrm__Checkout_Status !== null) {
            if (
              resp.pagarmeforzohocrm__Checkout_Status &&
              (resp.pagarmeforzohocrm__Checkout_Status === "closed" ||
                resp.pagarmeforzohocrm__Checkout_Status === "open")
            ) {
              setButton(true);
              toast.error("Já existe um pedido criado");
              return;
            }
          }
          if (resp.pagarmeforzohocrm__Order_Status !== null) {
            if (
              resp.pagarmeforzohocrm__Order_Status &&
              (resp.pagarmeforzohocrm__Order_Status === "pending" ||
                resp.pagarmeforzohocrm__Order_Status === "paid")
            ) {
              setButton(true);
              toast.error("Já existe um pedido criado");
              return;
            }
          }
        }
      }
    }
  }, [resp, dataOrg, installments]);

  return (
    <Box
      component="section"
      sx={{
        minWidth: "100%",
        maxWidth: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        backgroundColor: "#f8f9fa",
      }}
    >
      {!loading && resp && dataOrg && (
        <>
          <div className="Titulo">{text.title}</div>
          <Info resp={resp} text={text} />
          <Products resp={resp} text={text.table} setLoading={setLoading} />
          <Confirm
            resp={resp}
            dataOrg={dataOrg}
            text={text}
            button={button}
            setLoading={setLoading}
          />
        </>
      )}
    </Box>
  );
}
