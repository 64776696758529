// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../color/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.area {
  width: 100%;
}
.total_value {
  background-color: #fcfcfc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 3%;
  margin-top: 3%;
  display: flex;
  align-items: center;
  justify-items: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.resposta2 {
  border-radius: 3px;
  padding: 5%;
  color: var(--fonte);
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.line {
  padding-top: 1%;
  padding-right: 1%;
  font-size: 22px;
  padding-bottom: 1%;
  letter-spacing: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Confirm/Confirm.css"],"names":[],"mappings":"AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB;4EAC0E;AAC5E;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["@import url(\"../../color/colors.css\");\n.area {\n  width: 100%;\n}\n.total_value {\n  background-color: #fcfcfc;\n  padding: 10px;\n  border-radius: 5px;\n  text-align: center;\n  margin-bottom: 3%;\n  margin-top: 3%;\n  display: flex;\n  align-items: center;\n  justify-items: center;\n  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),\n    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);\n}\n.resposta2 {\n  border-radius: 3px;\n  padding: 5%;\n  color: var(--fonte);\n  font-size: 24px;\n  font-weight: bold;\n  display: flex;\n  align-items: center;\n}\n.line {\n  padding-top: 1%;\n  padding-right: 1%;\n  font-size: 22px;\n  padding-bottom: 1%;\n  letter-spacing: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
