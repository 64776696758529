// utils.js

export function formatPrice(value) {
  if (value == null) {
    return ""; // ou outra representação adequada para valores nulos ou indefinidos
  }

  let valueString = value.toString();
  let [integer, decimal] = valueString.split(".");

  if (!decimal) {
    return `${valueString}.00`;
  } else if (decimal.length === 1) {
    return `${valueString}0`;
  } else {
    return valueString;
  }
}
