import {
  Autocomplete,
  Container,
  Stack,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";

function Shipping({ crmFields, orgVariable, setOrgVariable, text }) {
  const [street, setStreet] = useState(
    orgVariable?.Shipping?.streetField || null
  );
  const [city, setCity] = useState(orgVariable?.Shipping?.cityField || null);
  const [state, setState] = useState(orgVariable?.Shipping?.stateField || null);
  const [code, setCode] = useState(orgVariable?.Shipping?.codeField || null);

  console.log(text);

  // Atualiza os estados quando orgVariable muda
  useEffect(() => {
    setStreet(orgVariable?.Shipping?.streetField || null);
    setCity(orgVariable?.Shipping?.cityField || null);
    setState(orgVariable?.Shipping?.stateField || null);
    setCode(orgVariable?.Shipping?.codeField || null);
  }, [orgVariable]);

  const handleSave = async () => {
    // Construa o objeto formData com os dados selecionados
    const formData = {
      streetField: street,
      cityField: city,
      stateField: state,
      codeField: code,
    };

    // Atualize orgVariable.mapping com formData
    orgVariable.Shipping = formData;

    // Salve orgVariable no Zoho CRM
    const update_config = {
      apiname: "pagarmeforzohocrm__pagar_me",
      value: JSON.stringify(orgVariable, null, 2),
    };

    await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", update_config);

    toast.success("Shipping saved successfully");
    setOrgVariable({ ...orgVariable }); // Atualize o estado com orgVariable
  };

  return (
    <Container sx={{ height: "70vh", width: "50%", paddingTop: "15px" }}>
      <Stack justifyContent={"center"} textAlign={"center"} spacing={2}>
        <Typography variant="h4" color={"#424242"}>
          {text.title}
        </Typography>
        <Stack spacing={2}>
          <Autocomplete
            id="street-autocomplete"
            size="small"
            disablePortal
            options={crmFields}
            getOptionLabel={(option) => option.title}
            sx={{ width: "100%" }}
            value={street}
            onChange={(event, newValue) => {
              setStreet(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                color="success"
                label={text.textfield1}
              />
            )}
          />
          <Autocomplete
            id="city-autocomplete"
            size="small"
            disablePortal
            options={crmFields}
            getOptionLabel={(option) => option.title}
            sx={{ width: "100%" }}
            value={city}
            onChange={(event, newValue) => {
              setCity(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                color="success"
                label={text.textfield2}
              />
            )}
          />
          <Autocomplete
            id="state-autocomplete"
            size="small"
            disablePortal
            options={crmFields}
            getOptionLabel={(option) => option.title}
            sx={{ width: "100%" }}
            value={state}
            onChange={(event, newValue) => {
              setState(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                color="success"
                label={text.textfield3}
              />
            )}
          />
          <Autocomplete
            id="code-autocomplete"
            size="small"
            disablePortal
            options={crmFields}
            getOptionLabel={(option) => option.title}
            sx={{ width: "100%" }}
            value={code}
            onChange={(event, newValue) => {
              setCode(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                color="success"
                label={text.textfield4}
              />
            )}
          />
        </Stack>
        <Stack direction="row" display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="success"
            sx={{ width: "39.3%" }}
            size="large"
            onClick={handleSave}
          >
            SAVE
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default Shipping;
