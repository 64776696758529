import { useEffect, useState } from "react";
import PaymentForm from "../../components/Payment/PaymentForm";
import {
  Container,
  Stack,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material/";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import "./Mapping.css";

const Installments = [
  { title: "1x", value: 1 },
  { title: "2x", value: 2 },
  { title: "3x", value: 3 },
  { title: "4x", value: 4 },
  { title: "5x", value: 5 },
  { title: "6x", value: 6 },
  { title: "7x", value: 7 },
  { title: "8x", value: 8 },
  { title: "9x", value: 9 },
  { title: "10x", value: 10 },
  { title: "11x", value: 11 },
  { title: "12x", value: 12 },
];

const Mapping = ({
  setShipping,
  orgVariable,
  setOrgVariable,
  text,
  crmFields,
  currencyField,
}) => {
  const [selectedCrmField, setSelectedCrmField] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [selectedDays, setSelectedDays] = useState(null);
  const [minExpiration, setMinExpiration] = useState(null);
  const [selectedTotalAmount, setSelectedTotalAmount] = useState(null);
  const [selectedFrete, setSelectedFrete] = useState(""); // Modificado para string vazia
  const [timeExpiration, setTimeExpiration] = useState(""); // Modificado para string vazia
  const [adjustment, setAdjustment] = useState("");
  const [discount, setDiscount] = useState("");
  const [selectedEntry, setSelectedEntry] = useState("");
  const tooltip = text.tooltip;

  useEffect(() => {
    if (orgVariable.mapping) {
      const {
        paymentLink,
        maxInstallments,
        paymentMethod,
        PaymentDay,
        minExpiration,
        totalAmount,
        frete,
        expiration,
        adjustment,
        discount,
        entry
      } = orgVariable.mapping;
      setSelectedCrmField(paymentLink);
      setSelectedInstallment(maxInstallments);
      setPaymentMethod(paymentMethod);
      setSelectedDays(PaymentDay);
      setMinExpiration(minExpiration);
      setSelectedTotalAmount(totalAmount);
      setSelectedFrete(frete);
      setTimeExpiration(expiration);
      setAdjustment(adjustment);
      setDiscount(discount);
      setSelectedEntry(entry);
    }
  }, [orgVariable.mapping]);

  const handleSave = async () => {
    if (
      selectedCrmField === null ||
      (paymentMethod.some((method) => method.value === "credit_card") &&
        selectedInstallment === null) ||
      paymentMethod.length === 0 ||
      (paymentMethod.some((method) => method.value === "boleto") &&
        selectedDays === null) ||
      (paymentMethod.some((method) => method.value === "pix") &&
        minExpiration === null)
    ) {
      toast.error("Please fill in all fields before saving.");
      return;
    }

    const formData = {
      paymentLink: selectedCrmField,
      maxInstallments: selectedInstallment,
      paymentMethod: paymentMethod,
      PaymentDay: selectedDays,
      minExpiration: minExpiration,
      frete: selectedFrete,
      totalAmount: selectedTotalAmount,
      expiration: timeExpiration,
      adjustment: adjustment,
      discount: discount,
      entry: selectedEntry
    };

    orgVariable.mapping = formData;

    const update_config = {
      apiname: "pagarmeforzohocrm__pagar_me",
      value: JSON.stringify(orgVariable, null, 2),
    };

    try {
      await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", update_config);
      toast.success("Mapping saved successfully");
      setOrgVariable(orgVariable); // Atualiza o estado após salvar com sucesso
    } catch (error) {
      console.error("Error saving mapping:", error);
      toast.error("Failed to save mapping. Please try again.");
    }
  };
  console.log(text);
  return (
    <Container sx={{ height: "70vh", width: "90%", paddingTop: "15px" }}>
      <Stack textAlign={"center"} spacing={2}>
        <Typography color={"#424242"} variant="h4">
          {text.title}
        </Typography>
        <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
          <Stack spacing={2} sx={{ width: "50%" }}>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-crm"
                options={crmFields}
                getOptionLabel={(option) => option.title}
                sx={{ width: "100%" }}
                value={selectedCrmField}
                onChange={(event, newValue) => {
                  setSelectedCrmField(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="success"
                    size="small"
                    label={text.textfield1}
                  />
                )}
              />
              <Tooltip title={tooltip.paymentLink} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Autocomplete
                size="small"
                disablePortal
                id="frete"
                options={currencyField}
                getOptionLabel={(option) => option.title}
                sx={{ width: "100%" }}
                value={selectedFrete}
                onChange={(event, newValue) => {
                  setSelectedFrete(newValue);
                  if (newValue === null) {
                    setShipping(false);
                  } else {
                    setShipping(true);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="success"
                    size="small"
                    label={text.textfield4}
                  />
                )}
              />
              <Tooltip title={tooltip.adjustmet} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Autocomplete
                id="valor total"
                size="small"
                disablePortal
                options={currencyField}
                getOptionLabel={(option) => option.title}
                sx={{ width: "100%" }}
                value={selectedTotalAmount}
                onChange={(event, newValue) => {
                  setSelectedTotalAmount(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="success"
                    size="small"
                    label={text.textfield5}
                  />
                )}
              />
              <Tooltip title={tooltip.totalValue} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <TextField
                color="success"
                size="small"
                sx={{ minWidth: "92.5%" }}
                label={text.textfield6}
                value={timeExpiration}
                onChange={(event) => {
                  setTimeExpiration(event.target.value);
                }}
              />
              <Tooltip title={tooltip.expirationCheckout} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-crm"
                options={currencyField}
                getOptionLabel={(option) => option.title}
                sx={{ width: "100%" }}
                value={adjustment}
                onChange={(event, newValue) => {
                  setAdjustment(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="success"
                    size="small"
                    label={text.textfield7}
                  />
                )}
              />
              <Tooltip title={tooltip.adjustmet} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-crm"
                options={currencyField}
                getOptionLabel={(option) => option.title}
                sx={{ width: "100%" }}
                value={discount}
                onChange={(event, newValue) => {
                  setDiscount(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color="success"
                    size="small"
                    label={text.textfield10}
                  />
                )}
              />
              <Tooltip title={tooltip.discount} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
          </Stack>
          <Stack spacing={2} sx={{ width: "50%" }}>
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <PaymentForm
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                text={text}
              />
              <Tooltip title={tooltip.paymentMethod} arrow>
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </Stack>
            {paymentMethod.some(
              (method) =>
                method.value === "credit_card" || method.value === "credits"
            ) && (
              <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={Installments}
                  getOptionLabel={(option) => option.title}
                  sx={{ width: "100%" }}
                  value={selectedInstallment}
                  onChange={(event, newValue) =>
                    setSelectedInstallment(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      color="success"
                      label={text.textfield3}
                    />
                  )}
                />
                <Tooltip title={tooltip.maxInstallments} arrow>
                  <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
              </Stack>
            )}
            {paymentMethod.some((method) => method.value === "boleto") && (
              <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                <TextField
                  label={text.textfield8}
                  size="small"
                  color="success"
                  type="number"
                  value={selectedDays}
                  onChange={(event) => {
                    setSelectedDays(event.target.value);
                  }}
                  sx={{ minWidth: "92.5%" }}
                />
                <Tooltip title={tooltip.expirationBoleto} arrow>
                  <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
              </Stack>
            )}
            {paymentMethod.some((method) => method.value === "pix") && (
              <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
                <TextField
                  label={text.textfield9}
                  size="small"
                  color="success"
                  type="number"
                  value={minExpiration}
                  onChange={(event) => {
                    setMinExpiration(event.target.value);
                  }}
                  sx={{ minWidth: "92.5%" }}
                />
                <Tooltip title={tooltip.expirationPix} arrow>
                  <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
              </Stack>
            )}
            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Autocomplete
                  size="small"
                  disablePortal
                  id="entrada"
                  options={currencyField}
                  getOptionLabel={(option) => option.title}
                  sx={{ width: "100%" }}
                  value={selectedEntry}
                  onChange={(event, newValue) => {
                    setSelectedEntry(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="success"
                      size="small"
                      label={text.textfield11}
                    />
                  )}
                />
                <Tooltip title={tooltip.entry} arrow>
                  <InfoIcon fontSize="small" color="disabled" />
                </Tooltip>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" display="flex" justifyContent="center">
          <Button
            sx={{ width: "20%" }}
            variant="contained"
            color="success"
            size="large"
            onClick={handleSave}
          >
            {text.button}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Mapping;
