// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 80%;
  padding: 20px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.loader {
  display: flex;
  align-items: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: 0.25s;
}

.bar:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/router.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,YAAY;EACZ,oCAAoC;EACpC,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".container {\n  height: 80%;\n  padding: 20px;\n  border-radius: 5px;\n  justify-content: center;\n  display: flex;\n  align-items: center;\n}\n.loader {\n  display: flex;\n  align-items: center;\n}\n\n.bar {\n  display: inline-block;\n  width: 3px;\n  height: 20px;\n  background-color: rgba(0, 0, 0, 0.5);\n  border-radius: 10px;\n  animation: scale-up4 1s linear infinite;\n}\n\n.bar:nth-child(2) {\n  height: 35px;\n  margin: 0 5px;\n  animation-delay: 0.25s;\n}\n\n.bar:nth-child(3) {\n  animation-delay: 0.5s;\n}\n\n@keyframes scale-up4 {\n  20% {\n    background-color: #ffff;\n    transform: scaleY(1.5);\n  }\n\n  40% {\n    transform: scaleY(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
