import React, { useEffect, useState } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import "./index.css";
import InputSettings from "../../components/settingsInput";
import Mapping from "../../components/Mapping/Mapping";
import Webhook from "../../components/webhook/webhook";
import Shipping from "../../components/Shipping/Shipping";

function CustomTabPanel(props) {
  const [freigth, setFreigth] = useState(null);
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = ({ text }) => {
  const [value, setValue] = useState(0);
  const [isblocked, setIsBlocked] = useState(true);
  const [crmFields, setCrmFields] = useState([]);
  const [currencyField, setCurrencyField] = useState([]);
  const [orgVariable, setOrgVariable] = useState({});
  const [shipping, setShipping] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [isOrgVariableChecked, setIsOrgVariableChecked] = useState(false);
  useEffect(() => {
    ZOHO.CRM.API.getOrgVariable("pagarmeforzohocrm__pagar_me").then(function (
      data
    ) {
      const resp = data.Success.Content;
      const jsonResponse = JSON.parse(resp);
      const { api_key } = jsonResponse;
      setOrgVariable(jsonResponse);
      if (api_key) {
        setApiKey(api_key);
        setIsBlocked(false);
      }
    });

    //Campos do CRM
    ZOHO.CRM.META.getLayouts({ Entity: "Quotes" }).then(function (data) {
      const layouts = data.layouts;
      for (let layout of layouts) {
        const sections = layout.sections;
        for (let section of sections) {
          const fields = section.fields;
          for (let field of fields) {
            if (field.data_type === "text" || field.data_type === "website") {
              let fieldOption = {
                title: field.field_label,
                value: field.api_name,
              };
              setCrmFields((prevFields) => [...prevFields, fieldOption]);
            } else if (
              field.data_type === "currency" ||
              field.data_type === "formula"
            ) {
              if (field.data_type === "formula") {
                if (field.formula.return_type === "currency") {
                  let fieldOption = {
                    title: field.field_label,
                    value: field.api_name,
                  };
                  setCurrencyField((prevFields) => [
                    ...prevFields,
                    fieldOption,
                  ]);
                }
              } else {
                let fieldOption = {
                  title: field.field_label,
                  value: field.api_name,
                };
                setCurrencyField((prevFields) => [...prevFields, fieldOption]);
              }
            }
          }
        }
      }
    });
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!isOrgVariableChecked && orgVariable.mapping) {
      if (
        orgVariable.mapping?.frete === undefined ||
        orgVariable.mapping?.frete == null
      ) {
        setShipping(false);
      } else {
        setShipping(true);
      }
      setIsOrgVariableChecked(true);
    }
  }, [orgVariable, isOrgVariableChecked]);
  return (
    <div className="mainOut">
      <div className="Settings">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                "& .MuiTab-root": {
                  color: "grey",
                },
                "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                  color: "green",
                },
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "green",
                },
              }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={text.navigattion.step1} {...a11yProps(0)} />
              <Tab
                label={text.navigattion.step2}
                {...a11yProps(1)}
                disabled={isblocked}
              />
              {shipping == true && (
                <Tab label={text.navigattion.step3} {...a11yProps(2)}></Tab>
              )}
              <Tab label={text.navigattion.step4} {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <InputSettings
              setIsBlocked={setIsBlocked}
              orgVariable={orgVariable}
              setOrgVariable={setOrgVariable}
              apiKey={apiKey}
              setApiKey={setApiKey}
              text={text.step1}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Mapping
              setShipping={setShipping}
              text={text.step2}
              orgVariable={orgVariable}
              setOrgVariable={setOrgVariable}
              crmFields={crmFields}
              currencyField={currencyField}
            />
          </CustomTabPanel>
          {shipping == true && (
            <CustomTabPanel value={value} index={2}>
              <Shipping
                text={text.step3}
                orgVariable={orgVariable}
                setOrgVariable={setOrgVariable}
                crmFields={crmFields}
              />
            </CustomTabPanel>
          )}
          <CustomTabPanel value={value} index={3}>
            <Webhook text={text.step4} orgVariable={orgVariable} />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default Settings;
