// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divCentral {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.SettingsApiKey {
  display: inline-block;
  padding: 2.2rem;
}

h2 {
  font-size: 37px;
}

.Textfield {
  margin: 20px 0;
}
.Buttons {
  display: flex;
  justify-content: space-between;
}

.apiText {
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/settingsInput/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".divCentral {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 70vh;\n}\n\n.SettingsApiKey {\n  display: inline-block;\n  padding: 2.2rem;\n}\n\nh2 {\n  font-size: 37px;\n}\n\n.Textfield {\n  margin: 20px 0;\n}\n.Buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\n.apiText {\n  font-size: 17px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
