import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";

const InstallmentsOptions = [
  { title: "1x", value: 1 },
  { title: "2x", value: 2 },
  { title: "3x", value: 3 },
  { title: "4x", value: 4 },
  { title: "5x", value: 5 },
  { title: "6x", value: 6 },
  { title: "7x", value: 7 },
  { title: "8x", value: 8 },
  { title: "9x", value: 9 },
  { title: "10x", value: 10 },
  { title: "11x", value: 11 },
  { title: "12x", value: 12 },
];

const InstallmentsComponent = ({
  setJsonSetupPcl,
  jsonSetupPcl,
  maxInstallmentsData,
}) => {
  const [selectedInstallment, setSelectedInstallment] =
    useState(maxInstallmentsData);
  const [percentual, setPercentual] = useState("0.00");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (selectedInstallment !== null) {
      const newOptions = [];
      for (let i = 2; i <= selectedInstallment.value; i++) {
        newOptions.push({ title: `${i}x`, value: i });
      }
      newOptions.push({ title: "Todas", value: 1 });
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [selectedInstallment]);

  const handleInstallmentChange = (event, newValue) => {
    setSelectedInstallment(newValue);

    setJsonSetupPcl({
      ...jsonSetupPcl,
      maxInstallments: newValue,
    });
  };

  const handlePercentualChange = (event) => {
    const value = event.target.value;
    setPercentual(value);

    setJsonSetupPcl({
      ...jsonSetupPcl,
      percentual: value,
    });
  };

  const handleOptionsChange = (event, newValue) => {
    setJsonSetupPcl({
      ...jsonSetupPcl,
      from: newValue,
    });
  };
  return (
    <Stack
      sx={{
        backgroundColor: "#fcfcfc",
        padding: 2,
        borderRadius: 5,
        boxShadow:
          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
        width: "65%",
      }}
      spacing={1}
    >
      <Autocomplete
        options={InstallmentsOptions}
        getOptionLabel={(option) => option.title}
        onChange={handleInstallmentChange}
        renderInput={(params) => (
          <TextField {...params} label="Máximo de parcelas" color="success" />
        )}
        sx={{ width: "100%" }}
        size="small"
        value={selectedInstallment}
      />
      <FormControl
        sx={{ m: 1, width: "100%" }}
        color="success"
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-percentual">
          Porcentagem
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-percentual"
          size="small"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "Percentual",
          }}
          type="number"
          value={percentual}
          onChange={handlePercentualChange}
          label="Porcentagem"
        />
      </FormControl>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.title}
        onChange={handleOptionsChange}
        renderInput={(params) => (
          <TextField {...params} label="A partir de:" color="success" />
        )}
        sx={{ width: "100%" }}
        size="small"
      />
    </Stack>
  );
};

export default InstallmentsComponent;
