import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatPrice } from "../../utils/formatPrice";
import { GlobalStyles } from "@mui/system";

const columns = [
  { id: "Product", label: "Product", minWidth: 350, Padding: "10px" },
  { id: "Quantity", label: "Quantity", minWidth: 50, Padding: "10px" },
  { id: "Price", label: "Price", minWidth: 50, Padding: "10px" },
  { id: "Total", label: "Total", minWidth: 50, Padding: "10px" },
];

function Products({ resp, text, setLoading }) {
  const [infoProducts, setInfoProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setInfoProducts(resp.Product_Details || []);
      console.log(resp.Product_Details);
    };
    fetchData();
  }, [resp]);

  return (
    <>
      <Paper sx={{ marginLeft: "1%", width: "98%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 242 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ Padding: "10px" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, Padding: "10px" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {infoProducts.map((product) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={product.product.name}
                >
                  <TableCell component="th" scope="row">
                    {product.product.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {product.quantity}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    R$ {formatPrice(product.list_price)}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderLeft: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    R$ {formatPrice(product.total)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default Products;
