import { useRef, useState, useEffect } from "react";
import { Stack, TextField, IconButton } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";

const Urlwebhook = ({text }) => {
  const textURLRef = useRef(null);
  const [webhookUrl, setWebhookUrl] = useState([]);

  useEffect(() => {
    ZOHO.CRM.API.getOrgVariable("pagarmeforzohocrm__webhook_url").then(function (
      data
    ) {
      const resp = data.Success.Content;
      if (resp) {
        setWebhookUrl(resp);
      }
    });
  }, []);

  // Copiar link
  const handleCopyURL = () => {
    if (textURLRef.current) {
      // Seleciona o texto dentro do TextField
      textURLRef.current.select();
      document.execCommand("copy");
      toast.info("Link copiado para a área de transferência");
    }
  };

  return (
    <Stack direction="row" spacing={0.5}>
      <TextField
        inputRef={textURLRef}
        value={webhookUrl}
        size="small"
        margin="dense"
        color="success"
      />
      <IconButton
        size="small"
        onClick={handleCopyURL}
        className="buttonCopyURL"
      >
        <MdContentCopy />
      </IconButton>
    </Stack>
  );
};

export default Urlwebhook;
