import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import "./styles.css";
import Tooltip from "@mui/material/Tooltip";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 20,
};

export default function BasicModal({ open, handleClose, text, value }) {
  const textAreaRef = React.useRef(null);

  const handleCopy = () => {
    if (textAreaRef.current) {
      // Seleciona o texto dentro do TextField
      textAreaRef.current.select();
      document.execCommand("copy");
      toast.info("Link copied to clipboard");
      ZOHO.CRM.UI.Popup.closeReload();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="containerCopy">
            <h2 className="titleModal">{text.modal}</h2>
            <div className="box_input">
              <TextField
                inputRef={textAreaRef}
                id="outlined-basic"
                label="Link"
                variant="outlined"
                color="success"
                value={value}
                className="inputText"
              />
              <Tooltip title="Copy">
                <button onClick={handleCopy} className="buttonCopy">
                  <MdContentCopy size={25} />
                </button>
              </Tooltip>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
