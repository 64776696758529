// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --fundo: #e4e1e1af;
  --fonte: #424242;
  --primeira: rgb(184, 184, 184);
  --secundario: #d1c4ca;
  --borda: #65a300;
}
`, "",{"version":3,"sources":["webpack://./src/color/colors.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,8BAA8B;EAC9B,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[":root {\n  --fundo: #e4e1e1af;\n  --fonte: #424242;\n  --primeira: rgb(184, 184, 184);\n  --secundario: #d1c4ca;\n  --borda: #65a300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
