import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { FaLongArrowAltRight } from "react-icons/fa"
import { Visibility, VisibilityOff } from "@mui/icons-material";

import "./index.css";
import ButtonSave from "../buttonSave";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";


export default function InputSettings({
  setIsBlocked,
  orgVariable,
  setOrgVariable,
  apiKey,
  setApiKey,
  text,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = async () => {
    if (apiKey === "") {
      toast.error("The key field is empty");
      return;
    }

    orgVariable.api_key = apiKey;

    const update_config = {
      apiname: "pagarmeforzohocrm__pagar_me",
      value: JSON.stringify(orgVariable),
    };

    await ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", update_config);

    setOrgVariable(orgVariable);
    toast.success("API-KEY saved successfully");
    setIsBlocked(false);
  };

  return (
    <div className="divCentral">
      <div className="SettingsApiKey">
        <h2 color="success">{text.title}</h2>
        <p className="apiText">
          {text.text1}
          <FaLongArrowAltRight size={20} className="arrowIcon" /> {text.text2}
          <FaLongArrowAltRight size={20} className="arrowIcon" /> {text.text3}
        </p>
        <div className="Textfield">
          <FormControl sx={{ m: 1, width: '80%' }} color="success" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Key</InputLabel>
            <OutlinedInput
              color="success"
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Key"
              value={apiKey}
              onChange={(e) => {
                setApiKey(e.target.value);
              }}
            />
          </FormControl>
        </div>
        <div className="Buttons">
          <ButtonSave onClick={handleClick} text={text} />
        </div>
      </div>
    </div>
  );
}
