import InstallmentsComponent from "../installments/installments";
import { verifyInstallments } from "../../utils/verifyInstallments";
import { formatstate } from "../../utils/formatState";
import { formatPrice } from "../../utils/formatPrice";
import { createRecord } from "../../utils/createRecord";
import Autocomplete from "@mui/material/Autocomplete";
import { format, addDays, parseISO } from "date-fns";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import BasicModal from "../ModalCopy";
import Swal from "sweetalert2";
import "./Confirm.css";

export default function Confirm({ resp, dataOrg, text, button, setLoading }) {
  const dataHora = new Date().toISOString();
  const dataHoraFormat = dataHora.split(".")[0].replace("T", " ");
  const dataHoraFormatClean = dataHoraFormat.replace(/\D/g, "");
  const docnum = dataHoraFormatClean + resp.Quote_Number;
  const [calculatedDate, setCalculatedDate] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const paymentday = Number(dataOrg.mapping.PaymentDay);
  useEffect(() => {
    const date = parseISO(today);
    const newDate = addDays(date, paymentday);
    const formattedDate = format(newDate, "yyyy-MM-dd");
    setCalculatedDate(formattedDate);
  }, [today, paymentday]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");
  const id = resp.id;
  const fieldTotal = dataOrg?.mapping.totalAmount.value;
  const fieldFrete = dataOrg?.mapping.frete?.value;
  const minExpiration = dataOrg?.mapping.minExpiration;
  const checkoutExpiration = dataOrg?.mapping.expiration;
  const valorFrete = resp?.[fieldFrete];
  const valorTotal = parseFloat((resp?.[fieldTotal]).toFixed(2));
  const discountField = dataOrg?.mapping.discount.value;
  const valorDesconto = resp?.[discountField];
  const entryField = dataOrg?.mapping.entry.value;
  const valorEntrada = resp?.[entryField];
  const adjustmentField = dataOrg?.mapping.adjustment.value;
  const adjustmentValue = resp[adjustmentField];
  const cityField = dataOrg?.Shipping.cityField;
  const codeField = dataOrg?.Shipping.codeField;
  const stateField = dataOrg?.Shipping.stateField;
  const streetField = dataOrg?.Shipping.streetField;
  const state = formatstate(resp[stateField]);
  const valorFinal =
    valorTotal * 100 + valorFrete * 100 + adjustmentValue * 100;
  const [jsonSetupPcl, setJsonSetupPcl] = useState({
    valorFinal: valorFinal - (valorDesconto * 100) - (valorEntrada * 100),
    maxInstallments: dataOrg.mapping.maxInstallments,
  });
  //Todas as formas a de pagamento
  const accepted_payment_methods = dataOrg.mapping.paymentMethod.map(
    (method) => method.value
  );
  //Todas menos credito e boleto
  const payments_2 = accepted_payment_methods.filter(
    (method) => method !== "credit_card_and_boleto"
  );
  //Todas menos Creditos e Credito_boleto
  const payments = payments_2.filter((method) => method !== "credits");
  const paymentLink = resp[dataOrg?.mapping.paymentLink.value];

  async function handleSubmit() {
    if (!jsonSetupPcl.maxInstallments) {
      toast.error("Preencha o Máximo de parcelas");
      return;
    }
    if (jsonSetupPcl.percentual) {
      if (!jsonSetupPcl.from) {
        toast.error("Preencha a partir de qual parcela será cobrado a taxa");
        return;
      }
    }
    //Verificar se o campo do Frete está preenchido
    if (!resp[cityField.value]) {
      toast.error("Preencha a Cidade...");
      return; // Impede que o código prossiga se a cidade não estiver preenchida
    }
    // Verifica se o campo do CEP está preenchido
    if (!resp[codeField.value]) {
      toast.error("Preencha o Código...");
      return;
    }
    // Verifica se o campo do estado está preenchido
    if (!resp[stateField.value]) {
      toast.error("Preencha o Estado...");
      return;
    }
    // Verifica se o campo da rua está preenchido
    if (!resp[streetField.value]) {
      toast.error("Preencha a Rua...");
      return;
    }
    const minutos = dataOrg.mapping.minExpiration;
    const segundos = minutos * 60;
    const dataHoraAtual = new Date();
    const dias = dataOrg.mapping.PaymentDay;
    const diasParaAdicionar = dias + 1;
    const diasParaAdicionarInt = parseInt(diasParaAdicionar, 10);
    const novaData = new Date(dataHoraAtual);
    novaData.setDate(novaData.getDate() + diasParaAdicionarInt);
    const dataform = novaData.toLocaleDateString("en-CA");
    let items = [];
    const products = resp.Product_Details;
    const total = parseFloat((valorTotal * 100).toFixed(2));
    //adicionando cada item na lista items
    products.map((respIten) => {
      const { list_price: amount, quantity } = respIten;
      const description = respIten.product.name;
      const item = {
        quantity,
        amount: amount * 100,
        description,
        code: respIten.product.Product_Code,
      };
      items.push(item);
    });

    //pegando informações do orgVariable
    const { maxInstallments } = dataOrg.mapping;
    const { api_key } = dataOrg;
    //criando o obj de checkout da pagar-me
    let jsonData = {
      customer: {
        name: resp.Contact_Name.name,
        confirm,
      },
      items,

      payments: [
        {
          amount: Number(
            valorFinal -
              (valorEntrada * 100) -
              (valorDesconto * 100).toFixed(0).replace(/\./g, "")
          ),
          payment_method: "checkout",
          checkout: {
            expires_in: checkoutExpiration,
            billing_address_editable: false,
            customer_editable: true,
            accepted_payment_methods: payments,
            success_url: "https://www.pagar.me",
          },
        },
      ],
    };
    if (
      accepted_payment_methods.includes("credits") &&
      accepted_payment_methods.includes("credit_card_and_boleto")
    ) {
      jsonData.payments[0].checkout.accepted_multi_payment_methods = [
        ["credit_card", "credit_card"],
        ["credit_card", "boleto"],
      ];
    } else if (accepted_payment_methods.includes("credit_card_and_boleto")) {
      jsonData.payments[0].checkout.accepted_multi_payment_methods = [
        ["credit_card", "boleto"],
      ];
    } else if (accepted_payment_methods.includes("credits")) {
      jsonData.payments[0].checkout.accepted_multi_payment_methods = [
        ["credit_card", "credit_card"],
      ];
    }
    if (valorFrete !== 0) {
      if (resp.Shipping_City !== "null") {
        jsonData.shipping = {
          amount: Number((valorFrete * 100).toFixed(0).replace(/\./g, "")),
          description: "Frete",
          address: {
            country: "BR",
            state: state,
            city: resp[cityField.value],
            zip_code: resp[codeField.value],
            line_1: resp[streetField.value],
          },
        };
      } else {
        toast.error("Preencha a Cidade..");
      }
    }
    if (
      accepted_payment_methods.includes("credits") ||
      accepted_payment_methods.includes("credit_card_and_boleto") ||
      accepted_payment_methods.includes("credit_card")
    ) {
      let installments;
      installments = verifyInstallments(jsonSetupPcl);
      jsonData.payments[0].checkout.credit_card = {
        installments: installments,
      };
    }
    if (accepted_payment_methods.includes("boleto")) {
      jsonData.payments[0].checkout.boleto = {
        instructions: "Pagar",
        due_at: calculatedDate,
        document_number: docnum,
        type: "DM",
      };
    }

    if (accepted_payment_methods.includes("pix")) {
      jsonData.payments[0].checkout.pix = {
        expires_in: segundos,
      };
    }
    const authString = `${dataOrg.api_key}:`;
    const base64AuthString = btoa(authString);
    const authorization = `Basic ${base64AuthString}`;
    var request = {
      url: "https://api.pagar.me/core/v5/orders",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        authorization,
      },
      body: JSON.stringify(jsonData),
    };
    await ZOHO.CRM.HTTP.post(request).then((data) => {
      const respCreateLink = JSON.parse(data);
      console.log(data);
      const payment = respCreateLink.checkouts?.[0]?.payment_url;
      if (payment) {
        setPaymentUrl(respCreateLink.checkouts[0].payment_url);
        Swal.fire({
          title: text.modal2.title,
          text: text.modal2.subtitle,
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        }).then(handleOpen);
      } else {
        toast.error("Erro ao criar o pedido...");
      }
      const fieldURL = dataOrg.mapping.paymentLink.value;
      var func_name = "pagarmeforzohocrm__Pagarme_inputResp";
      var req_data = {
        arguments: JSON.stringify({
          crmAPIRequest: {
            id: id,
            Name: resp.Subject + " - " + respCreateLink.id,
            APIName: fieldURL,
            [fieldURL]: respCreateLink.checkouts[0].payment_url,
            pagarmeforzohocrm__Checkout_Date:
              respCreateLink.checkouts[0].created_at,
            pagarmeforzohocrm__Checkout_Expiration_Date:
              respCreateLink.checkouts[0].expires_at,
            pagarmeforzohocrm__Checkout_ID: respCreateLink.checkouts[0].id,
            pagarmeforzohocrm__Checkout_Status:
              respCreateLink.checkouts[0].status,
            pagarmeforzohocrm__Order_ID: respCreateLink.id,
            pagarmeforzohocrm__Order_Code: respCreateLink.code,
            pagarmeforzohocrm__Order_Status: respCreateLink.status,
            pagarmeforzohocrm__Quote: resp.id,
          },
        }),
      };
      // Ativação de função em deluge para adicionar dados ao crm
      ZOHO.CRM.FUNCTIONS.execute(func_name, req_data)
        .then(function (data) {
          console.log(data);
        })
        .catch(function (error) {
          console.error("Erro ao executar a função:", error);
        });
    });
    //Cancelamento de pedido antigo
    if (resp.pagarmeforzohocrm__Order_Status !== null) {
      if (
        resp.pagarmeforzohocrm__Order_Status === "pending" ||
        resp.pagarmeforzohocrm__Order_Status === "paid"
      ) {
        var requestCancelled = {
          url: `https://api.pagar.me/core/v5/orders/${resp.pagarmeforzohocrm__Order_ID}/closed`,
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            authorization: authorization,
          },
          body: JSON.stringify({
            status: "canceled",
          }),
        };
        await ZOHO.CRM.HTTP.patch(requestCancelled).then((data) => {
          console.log(data);
        });
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    };
  });
  return (
    <div className="area">
      <Stack sx={{ paddingTop: "1%" }} spacing={2}>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={3.25} sx={{ paddingLeft: "1%" }}>
            <InstallmentsComponent
              setJsonSetupPcl={setJsonSetupPcl}
              jsonSetupPcl={jsonSetupPcl}
              maxInstallmentsData={dataOrg.mapping.maxInstallments}
            />
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              heigth: "70%",
              backgroundColor: "#fcfcfc",
              padding: 2,
              borderRadius: 5,
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
          >
            <Stack
              spacing={1}
              sx={{ alignItems: "center", width: "100%" }}
              color={"#4f4f4f"}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h5">{text.amount}</Typography>
                <Typography variant="h4">
                  R${" "}
                  {formatPrice(
                    (
                      valorTotal +
                      valorFrete +
                      adjustmentValue -
                      valorDesconto -
                      valorEntrada
                    ).toFixed(2)
                  )}
                </Typography>
              </Stack>
                <Grid container direction="column" spacing={0.5}>
                  <Grid
                    container
                    spacing={0.5}
                    borderTop={"solid 1px gray"}
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      xs={6}
                      container
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="subtitle2">
                          {text.value}
                        </Typography>
                        <Typography variant="subtitle1">
                          R$ {formatPrice(valorTotal)}{" "}
                        </Typography>
                      </Stack>
                      
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="subtitle2">
                            {text.shipping}
                          </Typography>
                          <Typography variant="subtitle1">
                            R$ {formatPrice(valorFrete)}
                          </Typography>
                        </Stack>
                    
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="subtitle2">
                            {text.adjustment}
                          </Typography>
                          <Typography variant="subtitle1">
                            R$ {formatPrice(adjustmentValue)}
                          </Typography>
                        </Stack>
                    </Grid>

                    {/* Container direito para Desconto e Entrada */}
                    <Grid
                      item
                      xs={6}
                      container
                      direction="column"
                      alignItems="flex-end"
                    >
                      
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="subtitle2">
                            {text.discount}
                          </Typography>
                          <Typography variant="subtitle1">
                            R$ {formatPrice(valorDesconto)}
                          </Typography>
                        </Stack>
                      
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="subtitle2">
                            {text.entry}
                          </Typography>
                          <Typography variant="subtitle1">
                            R$ {formatPrice(valorEntrada)}
                          </Typography>
                        </Stack>
                    </Grid>
                  </Grid>
                </Grid>
            </Stack>
          </Grid>
          <Grid item xs={3.25} sx={{ width: "100%" }}>
            {" "}
          </Grid>
        </Grid>
        <Stack
          justifyContent="center"
          direction="row"
          sx={{ marginTop: "1% !important" }}
        >
          <Button
            disabled={button}
            variant="contained"
            color="success"
            size="large"
            onClick={handleSubmit}
          >
            {text.button}
          </Button>
        </Stack>
      </Stack>
      <BasicModal
        handleClose={handleClose}
        open={open}
        text={text}
        value={paymentUrl}
      ></BasicModal>
    </div>
  );
}
