// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../color/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root {
  height: 100%;
  margin: 0;
}
.App {
  padding: 2%;
  background-color: var(--fundo);
}
.Titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  padding-top: 0.5%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/App/App.css"],"names":[],"mappings":"AACA;;;EAGE,YAAY;EACZ,SAAS;AACX;AACA;EACE,WAAW;EACX,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["@import url(\"../../color/colors.css\");\nhtml,\nbody,\n#root {\n  height: 100%;\n  margin: 0;\n}\n.App {\n  padding: 2%;\n  background-color: var(--fundo);\n}\n.Titulo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 28px;\n  font-weight: 700;\n  padding-top: 0.5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
