import { useEffect, useState } from "react";
import "./Info.css";
function Info({ resp, text }) {
  if (!resp) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <div className="tt">
        <div className="sup">
          <div className="infoConta">
            {text.subtitle} {resp.Contact_Name.name}
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
