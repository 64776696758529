export function verifyInstallments(jsonSetupPcl) {
  console.log(jsonSetupPcl);
  const from = jsonSetupPcl && jsonSetupPcl.from ? jsonSetupPcl.from.value : undefined;
  console.log(from);
  const maxInstallments = jsonSetupPcl.maxInstallments.value;
  const percentual = jsonSetupPcl.percentual;
  const valorFinal = jsonSetupPcl.valorFinal;
  const percentua_tratado = 1 + 0.01 * Number(percentual);
  const valor_percentual = (valorFinal * percentua_tratado).toFixed(2);
  const installments = [];
  for (let i = 1; i <= maxInstallments; i++) { 
    let installment;
    if(from){
      if (from <= i) {
        installment = {
          number: i,
          total: Number((valorFinal * percentua_tratado).toFixed(0).replace(/\./g, "")),
        }
      } 
      else {
        installment = {
          number: i,
          total: Number((valorFinal).toFixed(2))
        };
      }
  }
  else{
    installment = {
      number: i,
      total: Number((valorFinal).toFixed(2))
    };
  }
    installments.push(installment);
  }
  return installments;
}