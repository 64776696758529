// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../color/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tt {
  height: 80%;
  padding-bottom: 1%;
}
.sup {
  width: 98%;
  height: 13%;
}
.inf {
  width: 100%;
  height: 80%;
  display: flex;
}
.infoConta {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cobranca {
  width: 50%;
  height: 100%;
}
.entrega {
  width: 50%;
  height: 100%;
  display: block;
}
.data {
  margin-bottom: 1%;
  margin-right: 1%;
  max-width: 21%;
  max-height: 5%;
  font-size: 13px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.p {
  font-size: 10%;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/info/Info.css"],"names":[],"mappings":"AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,WAAW;EACX,WAAW;EACX,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,UAAU;EACV,YAAY;EACZ,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,cAAc;EACd,uBAAuB;EACvB,qBAAqB;AACvB","sourcesContent":["@import url(\"../../color/colors.css\");\n.tt {\n  height: 80%;\n  padding-bottom: 1%;\n}\n.sup {\n  width: 98%;\n  height: 13%;\n}\n.inf {\n  width: 100%;\n  height: 80%;\n  display: flex;\n}\n.infoConta {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n.cobranca {\n  width: 50%;\n  height: 100%;\n}\n.entrega {\n  width: 50%;\n  height: 100%;\n  display: block;\n}\n.data {\n  margin-bottom: 1%;\n  margin-right: 1%;\n  max-width: 21%;\n  max-height: 5%;\n  font-size: 13px;\n}\n.box {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n.p {\n  font-size: 10%;\n  margin-block-start: 0px;\n  margin-block-end: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
