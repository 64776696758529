// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  appearance: none;
  background-color: transparent;
  background-color: #65a300;
  border-radius: 0.4375em;
  box-sizing: border-box;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 0.8em 2.3em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button:disabled {
  pointer-events: none;
}

.button:hover {
  background-color: #74b808;
  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 7px;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: none;
  transform: translateY(0);
}
`, "",{"version":3,"sources":["webpack://./src/components/buttonSave/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB;+EAC6E;EAC7E,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;EACT,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,kBAAkB;EAClB,qBAAqB;EACrB,oDAAoD;EACpD,iBAAiB;EACjB,yBAAyB;EACzB,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,yCAAyC;EACzC,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B","sourcesContent":[".button {\n  appearance: none;\n  background-color: transparent;\n  background-color: #65a300;\n  border-radius: 0.4375em;\n  box-sizing: border-box;\n  border: none;\n  color: white;\n  cursor: pointer;\n  display: inline-block;\n  font-family: Roobert, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica,\n    Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\";\n  font-size: 18px;\n  font-weight: 600;\n  line-height: normal;\n  margin: 0;\n  min-width: 0;\n  outline: none;\n  padding: 0.8em 2.3em;\n  text-align: center;\n  text-decoration: none;\n  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);\n  user-select: none;\n  -webkit-user-select: none;\n  touch-action: manipulation;\n  will-change: transform;\n}\n\n.button:disabled {\n  pointer-events: none;\n}\n\n.button:hover {\n  background-color: #74b808;\n  box-shadow: rgba(0, 0, 0, 0.25) 0 3px 7px;\n  transform: translateY(-2px);\n}\n\n.button:active {\n  box-shadow: none;\n  transform: translateY(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
