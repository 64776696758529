import styles from "./index.css";
import Button from "@mui/material/Button";

export default function ButtonSave({ onClick, text }) {
  return (
    <Button
      variant="contained"
      color="success"
      size="extralarge"
      onClick={onClick}
    >
      {text.button}
    </Button>
  );
}
